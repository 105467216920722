import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { DraftContent } from 'ricos-content';
import { truncateContent } from 'ricos-content/libs/truncateContent';
import { WixRicosViewer, WixRicosViewerProps } from '@wix/ricos-viewer';
import { extractUoUParams } from '@wix/ricos-common/libs/extractUoUParams';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { selectUserToken } from 'store/selectors';

import { Show } from 'wui/Show';
import { TextButton } from 'wui/TextButton';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import { useRicosTheme } from './hooks/useRicosTheme';
import { useViewerPlugins } from './hooks/useViewerPlugins';

import classes from './ricos.scss';

export interface RichContentViewerProps
  extends Omit<WixRicosViewerProps, 'biSettings' | 'instance'>,
    TPAComponentProps {
  groupId?: string;
  truncate?: boolean;
  wired?: boolean;
  usage: 'About' | 'FeedItem' | 'CustomTab';
  contentId?: 'custom_tab' | 'about_group' | string;
}

export const RichContentViewer: React.FC<RichContentViewerProps> = (props) => {
  const { groupId, usage, className, contentId, wired, ...rest } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { language, isMobile } = useEnvironment();
  const plugins = useViewerPlugins(groupId as string);
  const instance = useSelector(selectUserToken);
  const theme = useRicosTheme({ parentClass: classes.viewer });

  const [isTruncated, setIsTruncated] = React.useState(props.truncate);

  const truncated = React.useMemo(
    () =>
      truncateContent(props.content as DraftContent, {
        wordsCount: 150,
        maxPlugins: 2,
        blocksCount: 6,
      }),
    [props.content],
  );

  const content = isTruncated
    ? (truncated.content as DraftContent)
    : props.content;

  return (
    <div
      data-hook={props['data-hook']}
      className={cls(classes.viewer, className, {
        [classes.mobile]: isMobile,
        [classes.wired]: wired,
      })}
    >
      <WixRicosViewer
        {...rest}
        theme={theme}
        content={content}
        instance={instance}
        plugins={plugins}
        locale={language}
        wixExperiments={experiments}
        linkSettings={{
          anchorTarget: '_blank',
          rel: { nofollow: true, ugc: true },
        }}
        biSettings={{
          usage,
          contentId,
          consumer: 'Groups',
          platform: 'Livesite',
          defaultParams: extractUoUParams({ biLogger: bi }),
        }}
      />

      <Show if={props.truncate && truncated.isTruncated}>
        <TextButton wired variant="link" onClick={toggleTruncateClick}>
          {isTruncated
            ? t('groups-web.discussion.feed.see-more')
            : t('groups-web.discussion.feed.see-less')}
        </TextButton>
      </Show>
    </div>
  );

  function toggleTruncateClick() {
    setIsTruncated((isTruncated) => !isTruncated);
  }
};

RichContentViewer.displayName = 'RichContentViewer';
RichContentViewer.defaultProps = {
  wired: true,
};
